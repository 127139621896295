<template>
  <HeroQuickLinksButton
    v-editable="blok"
    icon="photo"
    :label="blok.label"
    @click="onClick"
  />
</template>

<script setup lang="ts">
import type { BlockHeroQuickLinksMenuPhotoGalleryStoryblok } from '@/types/storyblok'

defineProps<{ blok: BlockHeroQuickLinksMenuPhotoGalleryStoryblok }>()

const { togglePhotoGallery } = useUIStore()

function onClick(): void {
  togglePhotoGallery(true)
}
</script>
